import "./OrderDetail.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import Unpaid from "./../../assets/images/unpaid.png"
import Paid from "./../../assets/images/paid.png"
import React, { useRef } from "react";
import { useCallback, useEffect, useState } from "react";

import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { serveOrder, cancelOrder, onBanUser, processingOrder } from "../../slices/serveOrderSlice";
import { getAnynetStaticProvider, NetworkId } from "../../constants";
import { Wallet } from "ethers";
import Clock from "./../../assets/images/clock.png"

import oldDrink from "./../../assets/images/OldFashion.png"
import { confirmAlert, ReactConfirmAlertProps } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ClassNames } from "@emotion/react";
import { getData, storeData } from "../../store/AppStorage";
import tokenImg from "./../../assets/images/loot8-token.png"
import cashImg from "./../../assets/images/cash-logo.png"






function OrderDetail() {
  const { order, isShowHours, isServeCliked, onServe, onClock, isOrderShow, selectOrderDetails, networkId, address, staticProvider, wallet } = useWeb3Context();
  const [isCancel, setIsCancel] = useState(false);
  const [isOrderShown, setIsOrderShown] = useState(isOrderShow);
  const [open, setOpen] = useState(false);
  const [isReverted, setIsReverted] = useState(false);
  const [isBan, setIsBan] = useState(false);
  const [timerCancel, setTimerCancel] = useState(0);
  const [userName, setUserName] = useState("");
  const [hour, setHour] = useState("");
  const dispacth = useAppDispatch();

  function truncate(source, size) {
    if (source)
      return source.length > size ? source.slice(0, size - 1)+"..." : source;
    return "";
  }
  const userNameId = truncate(order.userName, 18) + " #" + order.userID;
  //const drinkName = truncate(order.drinkName, 80);

  const onServeOrder = async (orderData: any, isServeCliked: boolean) => {
    onServe(true);
    await dispacth(processingOrder({ networkID: networkId, provider: staticProvider, orderInfo: orderData }));
    dispacth(serveOrder({ networkID: networkId, provider: staticProvider, address, orderInfo: orderData, wallet }));
    onServe(false);
    selectOrderDetails(null, false);
    // if (isServeCliked) {
    //   setTimeout(() => {
    //     onServe(false);
    //   }, 3000);
    // }

  }

  const onCancelOrder = async (orderData: any) => {
    setIsCancel(false);
    setIsReverted(false);
    onServe(true);
    await dispacth(processingOrder({ networkID: networkId, provider: staticProvider, orderInfo: orderData }));
    dispacth(cancelOrder({ networkID: networkId, provider: staticProvider, address, orderInfo: orderData, wallet }));
    onServe(false);
    selectOrderDetails(null, false);
    // setTimeout(() => {      
            
    //      }, 3000);
    
  }
  // const clearDetailMSG = async () => {
  //   setIsCancel(false);
  //   setIsReverted(false);
  //   selectOrderDetails(null, false);
  //   onServe(false);
  //   onClock(false);
  // }
  // let timeoutID: any;
  // useEffect(() => {
  //   if (isReverted) {
  //     timeoutID = setTimeout(async () => {
  //       console.log("timeout");
  //       // updateServed(order);
  //       await onCancelOrder(order);
  //       clearDetailMSG();
  //     }, 2000);
  //   }
  // }, [isReverted]);

  let timer: any = null;
  const startTimer = () => {
    timer = 10 * 1000;
    setTimerCancel(timer);
    // updateTime();
  }
  const updateTime = useEffect(() => {
    console.log("timerCancel",timer, timerCancel);
    if (timerCancel != 0 && timerCancel != null && timerCancel > 0) {
      console.log("timerCance>0",timer, timerCancel);
      timer = timerCancel - 1000;
      if (timer == 0) {
        onConfirmCancel();
      }
      else {
        setTimeout(() => {
          setTimerCancel(timer);
        }, 1000);
      }
    }
    // else if (timerCancel != 0 && timerCancel != null) {
    //   console.log("need to call cancel");

    // }
    else {
      console.log("timerer calling nulls")
    }
  }, [timerCancel]);

  // useEffect(() => {
  //   if (isReverted) {
  //     timeoutID = setTimeout(async () => {
  //       console.log("timeout");
  //       // updateServed(order);
  //       await onCancelOrder(order);
  //       clearDetailMSG();
  //     }, 2000);
  //   }
  // }, [isReverted]);

  // useEffect(() => {
  //   console.log("order-changed");
  //   revert();
  //   clearTimeout(timeoutID);
  // }, [order]);

  function cancel(orderData: any) {
    setIsCancel(true);
    setIsReverted(true);
    startTimer();
    // console.log("cancel order");
    // onCancelOrderPop();
    // onCancelOrder(orderData);
  }

  // function revert() {
  //   setIsCancel(false);
  //   console.log("clearTimeout");
  //   clearTimeout(timeoutID);
  //   setIsReverted(false);
  // }

  function onClickClock() {
    onClock(true);
  }

  // const onClickBan = () => {
  //   confirmAlert({
  //     title: "Confirm Action",
  //     message: "You are about cut off for 12hr.",
  //     buttons: [
  //       {
  //         label: "Save",
  //         onClick: () => alert("Click Yes")
  //       },
  //       {
  //         label: "Cancel"
  //         // onClick: () => alert("Click No")
  //       }
  //     ], closeOnEscape: true,
  //     closeOnClickOutside: true,
  //     //overlayClassName: "overlay-custom-class-name"

  //   });
  // };

  const onConfirmCancel = () => {
    timer = null;
    setTimerCancel(0);
    setIsCancel(false);
    setIsReverted(false);
    console.log("on-confirmcacel");
    onCancelOrder(order);
  }
  const onRevertCancel = () => {
    timer = null;
    setTimerCancel(0);
    setIsCancel(false);
    setIsReverted(false);
    console.log("onrevertCancel");
  }
  // const onCancelOrderPop = () => {
  //   startTimer();
  //   confirmAlert({
  //     title: "Confirm Action",
  //     message: "YAre you Sure.. need to Cancel Order?",
  //     buttons: [
  //       {
  //         label: "Save(" + timer + ")",
  //         onClick: () => onConfirmCancel
  //       },
  //       {
  //         label: "Cancel",
  //         onClick: () => onRevertCancel
  //       }
  //     ], closeOnEscape: false,
  //     closeOnClickOutside: false,
  //     overlayClassName: "custom-ui"
  //   });
  // };

  // const onCancelOrderPop = () => {
  //   startTimer();
  //   // setIsCancel(true);
  //   // custmAlrt=confirmAlert({
  //   //   closeOnEscape: false,
  //   //   closeOnClickOutside: false,
  //   //   customUI: ({ onClose }) => {
  //   //     return (
  //   //       <div className="custom-ui">
  //   //         <h1 className="alert_h1">Cancel Order</h1>
  //   //         <p>Are you Sure.. need to Cancel Order?</p>

  //   //         <button className="custom-ui_Cancel_button" onClick={() => {
  //   //           onRevertCancel();
  //   //           onClose();
  //   //         }}>Cancel</button>
  //   //         <button className="custom-ui_save_button"
  //   //           onClick={() => {
  //   //             onConfirmCancel();
  //   //             onClose();
  //   //           }}>
  //   //           Save({this.timer/1000})
  //   //         </button>
  //   //       </div>
  //   //     );
  //   //   }
  //   // });
  // };

  const onCloseBan = () => {
    setIsBan(false);
    console.log("onCloseBan");
  }
  const onSaveBan = async () => {
    setIsBan(false);
    onServe(true);
    await dispacth(onBanUser({ networkID: networkId, provider: staticProvider, address, orderInfo: order, wallet, banTime: hour }));
    onServe(false);
    selectOrderDetails(null, false);
    console.log("onSaveBan");
  }

  const handleClickCustom = (userName, hour) => {
    setUserName(userName);
    setHour(hour)
    setIsBan(true);
    // custmAlrt = confirmAlert({
    //   closeOnEscape: false,
    //   closeOnClickOutside: false,
    //   customUI: ({ onClose }) => {
    //     return (
    //       <div className="custom-ui">
    //         <h1 className="alert_h1">Confirm Action</h1>
    //         <p>You are about tto cutoff</p>
    //         <p className="alertname"><b> {userName}</b> &nbsp;for&nbsp; <b>{hour}</b>hr.</p>
    //         <button className="custom-ui_Cancel_button" onClick={() => {
    //           onCloseBan(); onClose();
    //         }}>Cancel</button>
    //         <button className="custom-ui_save_button"
    //           onClick={() => {
    //             // removeRecord(indexToRemove)
    //             onSaveBan();
    //             onClose();
    //           }}
    //         >
    //           Save
    //         </button>
    //       </div>
    //     );
    //   }
    // });
  };
  console.log(order);


  return (

    <div className="serveDiv">
      <>
        <Dialog maxWidth="xs"
          open={isReverted}
          onClose={() => { onRevertCancel(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <div className="custom-ui">
            <h1 className="alert_h1">Cancel Order</h1>
            <p>Are you sure you want to cancel this Order?</p>

            <button className="custom-ui_Cancel_button" onClick={() => {
              onRevertCancel();
            }}>No</button>
            <button className="custom-ui_save_button"
              onClick={() => {
                onConfirmCancel();
              }}>
              Yes({timerCancel / 1000})
            </button>
          </div>
        </Dialog>
      </>
      <>
        <Dialog maxWidth="xs"
          open={isBan}
          onClose={() => { onCloseBan(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <div className="custom-ui">
            <h1 className="alert_h1">Confirm Action</h1>
            <p className="alertname">Are you sure, you want to cutoff</p>
            <p className="alertname"><b> {userName}</b> &nbsp;for&nbsp; <b>{hour}</b>hrs?</p>
            <button className="custom-ui_Cancel_button" onClick={() => {
              onCloseBan();
            }}>No</button>
            <button className="custom-ui_save_button"
              onClick={() => {
                // removeRecord(indexToRemove)
                onSaveBan();
              }}
            >
              Yes
            </button>
          </div>
        </Dialog>
      </>
      {/* {isCancel && <div className="loader">
        <div className="ld1">
          <CircularProgress />
        </div>
      </div>
      } */}

      {isServeCliked && <div className="serveLoader">
        <div className="ld1">
          <CircularProgress />
        </div>
      </div>
      }

      <div className="topData">

        <div className="profileImage">
          <img src={order.userImage} style={{ backgroundColor: order.backgroundColor }} referrerPolicy="no-referrer" alt="profie pic" />
        </div>
        <div className="profileNameBox">
          <div className="profileNameText">{userNameId}</div>
        </div>
        <div className="drinkImgBox" style={{ backgroundImage: "url(" + order.drinkImage + ")" }}>


          {order.isPaid ? (<img src={Paid} alt="payinfo" />) : (<img src={cashImg} alt="payinfo" />)}



          <div className="priceBox">
            <div className="priceText">
              <div className="price">${order.price.toFixed(2)} </div>
              <div className="token">
                <div className="num">{order.token}</div>
                <div className="img"><img src={tokenImg} alt="token"></img></div>
              </div>
            </div>
          </div>

        </div>

        <div className="drinkTextBox">
          <div className="drinkText">
          {order.drinkName}
          </div>
        </div>
        <div className="drinkTextBox" style={{ marginTop: 0}}>
          <div className="drinkText" style={{ padding: 0, paddingBottom: 5}}>
            <span>{"Ord - #" + order.reservationId}</span>
          </div>
        </div>

        {/* {!isCancel &&
          

        } */}

        <div className="actionNewDiv"  >
          <button className="cancelserve" onClick={() => cancel(order)}>Cancel</button>
          <button className={order.isPaid ? "paidserve" : "unpaidserve"} onClick={() => onServeOrder(order, true)} >Serve</button>
          {/* <div className="clockBox">
            <img src={Clock} alt="clock" onClick={() => onClickClock()} />
          </div> */}
        </div>
        {/* {
          isCancel && <div className="actionNewDiv">
            <button className="cancelserve" onClick={() => revert()} >
              Revert
            </button>
          </div>
        } */}
        {isShowHours && <div className="hourBox">
          <div className="hourText" onClick={() => handleClickCustom(order.userName, 1)}>1hr</div>
          <div className="hourText" onClick={() => handleClickCustom(order.userName, 12)}>12hr</div>
          <div className="hourText" onClick={() => handleClickCustom(order.userName, 24)}>24hr</div>
          <div className="hourBanText">Ban</div>

        </div>
        }
      </div>


    </div>
  );
}

export default OrderDetail;