
import "./App.scss";
import { useWeb3Context } from "./hooks/web3Context";
import React, { useEffect } from "react";
import { Login, MainApp } from "./views";
import { getData, storeData } from "./store/AppStorage";
import { APP_STORAGE_USER_KEY } from "./constants";

function App() {
  const { connected, connect, address } = useWeb3Context();

  useEffect(() => {
    const isSessionExists = async () => {
      let isUserExists = await getData(APP_STORAGE_USER_KEY);
      if (isUserExists) {
        await connect();
      }
    };
    isSessionExists();
  }, [])
  
  return (
    <>
      {connected && address != "" ? (<MainApp />) : (<Login />)}
    </>
  );
}

export default App;