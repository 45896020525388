import React, { useEffect, useState } from "react";
import "./MainApp.scss";
import Dialog from "@mui/material/Dialog";
//import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import { height } from "@mui/system";
import { useWeb3Context } from "../../hooks/web3Context";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { clearAllData } from "../../store/AppStorage";
import Snackbar from "@mui/material/Snackbar";

export default function WalletMsgDialog() {
  const [open, setOpen] = React.useState(true);  
  const [showPopup, setShowPopup] = useState(false);
  const { disconnect, connected, networkId, address, staticProvider, userInfo, wallet } = useWeb3Context();
  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {   
    setOpen(false);     
    disconnect();
  };
  const copy = async (text) => {
    console.log(text);
    await navigator.clipboard.writeText(text)   
     setShowPopup(true);
  }
    
  useEffect(() => {
    setTimeout(() => setShowPopup(false), 4000);
    }, [showPopup]);
   
  return (
    <div>

      <Dialog
        open={open}
        onClose={handleToClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="popup"

        PaperProps={{          
          style: {
            // boxShadow: 'none',
            // //overflowX:"unset",
            // overflowY: 'unset',
            // width: '600px',
            // maxHeight: "700px",
            backgroundImage: "linear-gradient(#1b1b34, #2d1d3e)"
          },
        }}

      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white" }}
        >Hi {userInfo?.name}
        </DialogTitle>
        <DialogContent id="alert-dialog-description"
          sx={{ color: "white", textAlign: "center", marginTop: "10px", overflowX: "unset" }}>
          <p>Please note - Your user address is</p> {address}
          <span className="copyICON"><ContentCopyIcon sx={{ color: "#e9b313ee" }} onClick={() => { copy(address) }} /></span>
          {
            showPopup&&<span className="custom-tooltip">Copied!</span>
          }  
          
         <p>You do not have access yet.</p>

          <p>Please  request the
            <b style={{ color: "#e9b313ee" }}> Entity Admin</b> or
            <b style={{ color: "#e9b313ee" }}>&nbsp;Manager</b></p>
          <p>to provide access to you</p>

        </DialogContent>
        <DialogActions

        >
          <Button className="button" onClick={handleToClose} color="primary">
            Logout
          </Button>


        </DialogActions>
      </Dialog>
    </div>


  );
}