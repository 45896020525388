//import   "./Header.scss";
import React from "react";
import { useWeb3Context } from "../../hooks/web3Context";
import Header_Logo1 from  "/assets/images/loot_header_logo.svg";

import Header_Logo  from "./../../assets/images/header-logo.png";
import Header_Logo2  from "./../../assets/images/Header_Logo.png";
import LogoutIcon from '@mui/icons-material/Logout';
import { loadBartender, setLoading } from "../../slices/EntitySlice";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { useAppSelector } from "../../hooks/hooks";
import HeaderLogoBeta from "./../../assets/images/header-logo-beta.png"
import ClubTrendLogo from "./../../assets/images/club-trend-logo.png"





//./../../assets/images/CapDeF-logo.png"

///images/CapDeF-logo.png";




function Headers(){
        
    const { disconnect, connected, networkId, address, staticProvider, userInfo, wallet } = useWeb3Context();
    const dispatch = useDispatch<AppDispatch>();
    //console.log("userInfo:",userInfo)
    const profileImg = userInfo?.profileImage && userInfo?.profileImage != '' ? userInfo?.profileImage : require('../../assets/images/loot8-default-avatar.png'); 
     const entityLogo = useAppSelector(state => state.entity.entityLogo);
     //console.log("entityLogo:",entityLogo)
     console.log("userInfo:",userInfo)
     function parseUserName(userInfo) {
      if (userInfo){
        let userName = userInfo?.name;
        if(userName.toLowerCase().includes('@')){
         userName =  userInfo?.name.split("@")[0];               
        }
        return userName;
    }
      
    }
    let userName = parseUserName(userInfo);
       
    return(
          <div className="header-top">
            <img src={HeaderLogoBeta} alt="Loot8" className="logo_img"></img>
            <span className="divider"></span>
            <img src={entityLogo} alt="Loot8" className="entity_logo_img"></img> 
            
            <div className="user_text">{userName}</div>
            
           <img src={profileImg} referrerPolicy="no-referrer" className="user_img" ></img>
            {/* <span className="logout_button" onClick={() => disconnect()}><LogoutIcon /></span> */}
           
          </div>
    );
}

export default Headers;
