import { PrivateForwarder } from "../typechain/PrivateForwarder";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store/store";
import { BigNumber, ethers } from "ethers";
import { getAnynetStaticProvider, addresses, NetworkId, WEBHOOK_URL } from "../constants"
import { EntityRegistry__factory, Entity__factory, PrivateForwarder__factory, User__factory } from "../typechain/factories";
import { GetCreateCancelOrderMessage, GetCreateOrderMessage } from "../helpers/MsgHelper";

import { setAll } from "./helpers";
import { IAddressBaseAsyncThunk, IServeOrderAsyncThunk, IBaseAsyncThunk, IMessageMetaData, IEntityData, IBartender, IOrderAddedData, IOffer, UserOrderDetail, IWalletBaseAsyncThunk } from "./interfaces"
import { getIPFSData, getIPFSLink } from "../helpers/ipfs";


export interface IEntitySliceData {
  readonly EntityData?: any;
  readonly loading: boolean;
  readonly isBartenderAuthorized: boolean;
  readonly Bartender?: IBartender[];
  readonly entityLogo?: string;
}

const initialState: IEntitySliceData = {
  EntityData: null,
  loading: false,
  isBartenderAuthorized: false,
  Bartender: null,
  entityLogo: null,
};

export const getEntityBartenderDetails = async ({ networkID, provider, address }: { networkID, provider, address }): Promise<any> => {
  let bartendersList: IBartender[] = [];
  let allentityDetails: any = {};
  try {
    console.log("getEntityDetails:");
    const allentity = EntityRegistry__factory.connect(addresses[networkID].EntityRegistry, provider);
    const getAllEntities = await allentity.getAllEntities(true); //todo:pass the entity address later

    await Promise.all(getAllEntities.map(async entityAddress => {
      const entity = Entity__factory.connect(entityAddress, provider);
      let bartenderObj: IBartender;
     const entityData = await entity.getEntityData();
     
      if (entityData.isActive) {
        const bartender = await entity.getBartenderDetails(address);
        console.log("bartednr-id:",Number(bartender.id) );
        if (Number(bartender.id) != 0) {
          const entityDataURI =  entityData.dataURI;
          let response = await getIPFSData(entityDataURI);
          let responseJson = await response.json();
          allentityDetails[entityAddress] = responseJson;
          allentityDetails[entityAddress].logo = responseJson.meta.logo ? getIPFSLink(responseJson.meta.logo) : "";
          allentityDetails[entityAddress].backgroundImage = responseJson.meta.backgroundimage ? getIPFSLink(responseJson.meta.backgroundimage) : "";
        
          console.log(bartender);
          bartendersList.push({
            ...bartenderObj,
            id: bartender.id,
            address: address,
            // dataURI: bartender.dataURI,
            isActive: bartender.isActive,
            entityAddress: entityAddress
          });
        }
      }
    }));

  } catch (e) {
    console.log("Error occurred in getEntityBartenderDetails{}:", e)
  }

  return { bartenderList: bartendersList, allentityDetails: allentityDetails };

}

export const loadBartender = createAsyncThunk("app/loadBartender", async ({ networkID, provider, address, wallet }: IWalletBaseAsyncThunk, { dispatch }): Promise<any> => {
  let details = (await getEntityBartenderDetails({ networkID, provider, address }));
  // console.log("bartenderList:", details);
  let isAuthorized = false;
  let bartender: IBartender[];
  console.log(details.bartenderList);
  if (details.bartenderList != null && details.bartenderList.length > 0) {
    isAuthorized = true;
    bartender = details.bartenderList;
  }

  let selectedEntityLogo = null;
  Object.entries(details.allentityDetails).map(([key, value]) => {
    selectedEntityLogo = value["logo"];
    return;
  });
  return { isBartenderAuthorized: isAuthorized, Bartender: bartender, EntityData: details.allentityDetails, entityLogo: selectedEntityLogo };
});
export const setLoading = createAsyncThunk("Passports/setPassportLoading", async (): Promise<any> => {
  return { loading: true };
});
const EntitySlice = createSlice({
  name: "Entity",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadBartender.fulfilled, (state: { loading: boolean; }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(setLoading.fulfilled, (state: { loading: boolean; }, action: { payload: any }) => {
        state.loading = true;
      })
  },
});

export const entitySliceReducer = EntitySlice.reducer;

const baseInfo = (state: RootState) => state.entity;

export const { fetchAppSuccess } = EntitySlice.actions;

export const getEntityState = createSelector(baseInfo, Entity => Entity);
