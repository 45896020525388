import { CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import { Web3Auth } from "@web3auth/modal";

import React, { Dispatch, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import RPC from "./../ethersRPC";
import { StaticJsonRpcProvider } from "@ethersproject/providers";

import { APP_STORAGE_USER_KEY, getNetwork, getNetworkName, getNetworkRPC, getNetworkURI, getStaticProvider } from "../constants";
import { EnvHelper } from "../Environment";
import { clearAllData, getData, storeData } from "../store/AppStorage";
import { ethers } from "ethers";

type web3AuthDetailsTypes = {
  connect: () => void;//Promise<Web3Auth | undefined>;
  disconnect: () => void;
  web3auth: Web3Auth | null,
  address: string;
  connected: boolean;
  networkId: number;
  networkName: string;
  blockExplorerUrl: string;
  providerUri: string;
  provider: any;
  userInfo: any;
  staticProvider: any;
  userBalance: string;
  order: any;
  isOrderShow: boolean;
  selectOrderDetails: (order: any, isOrderShow: boolean) => void;
  // allorderDetails:any[];
  // updateServed:(order:any)=>void;
  // orderDetails :(order:any,isOrderShow:boolean) => void;
  // hideOrderDetails : (order:any,isOrderShow:boolean) => void;
  wallet: any;
  isShowHours: boolean;
  isServeCliked: boolean;
  onServe: (isServe: boolean) => void;
  onClock: (isHoursClick: boolean) => void;

};
export type Web3ContextData = {
  web3AuthDetails: web3AuthDetailsTypes;
} | null;

// // const clientId = EnvHelper.env.REACT_APP_CLIENTID ?? "";
// const clientId = "BIZImoKaH-3r1DFVSxnIvR8jiL78uMfbJT0iaamBOBfWQkq7kDVJel6XaTCeOKz6EvHtgAPYnU2bxqu3qfZ1X1s";
// const appInfuraId = EnvHelper.env.REACT_APP_INFURA_IDS ?? "";
// const networkHEX =  "0xA4B1";//EnvHelper.env.REACT_APP_NETWORKHEX ??
// const chainrpc = "https://arbitrum-mainnet.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f";//EnvHelper.env.REACT_APP_CHAINRPC ?? 

const clientId = EnvHelper.env.REACT_APP_CLIENTID;
const appInfuraId = EnvHelper.env.REACT_APP_INFURA_IDS;
const networkHEX = EnvHelper.env.REACT_APP_NETWORKHEX;
const chainrpc = EnvHelper.env.REACT_APP_CHAINRPC;

// const defaultNetworkDev: any = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   netWorkName: "",
//   chainId: "0xA4B1",
//   rpcTarget: "https://arbitrum-mainnet.infura.io/v3/" + appInfuraId, // This is the public RPC we have added, please pass on your own endpoint while creating an app
// };

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, please declare it at a higher level.",
    );
  }
  const { web3AuthDetails } = web3Context;
  return useMemo<web3AuthDetailsTypes>(() => {
    return { ...web3AuthDetails };
  }, [web3AuthDetails]);
};



export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {

  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState("");
  const [provider, setProvider] = useState<SafeEventEmitterProvider>();
  const [networkId, setNetworkId] = useState(0);
  const [userInfo, setUserInfo] = useState<Partial<any>>();
  const [networkName, setNetworkName] = useState("");
  const [userBalance, setUserBalance] = useState("");
  const [providerUri, setProviderUri] = useState("");
  const [blockExplorerUrl, setBlockExplorerUrl] = useState("");
  const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
  const [staticProvider, setStaticProvider] = useState<StaticJsonRpcProvider | undefined>(undefined);
  const [order, setOrder] = useState("");
  const [isOrderShow, setIsOrderShow] = useState(false);
  const [wallet, setWallet] = useState(undefined);
  const [isShowHours, setIsShowHours] = useState(false);
  const [isServeCliked, setIsServeCliked] = useState(false);
  //   InitWeb3Auth();
  const connect = useCallback(async () => {
    console.log("calling connect");
    let web3authProvider = await getData("web3authProvider");
    let web3auth_l = await getData("web3auth");
    if (web3authProvider == null) {
      web3auth_l = new Web3Auth({
        clientId,
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: networkHEX,
          rpcTarget: chainrpc
        },
        web3AuthNetwork: (EnvHelper.env.REACT_APP_ENV == "development") ? 'testnet' : 'cyan'
      });
      await web3auth_l.initModal();
      web3authProvider = await web3auth_l.connect();
      setWeb3auth(web3auth_l);
      setProvider(web3authProvider);
      let userinfo = await web3auth_l.getUserInfo();
      let rpc = new RPC(web3authProvider);
      const address = await rpc.getAccounts();
      const pKey = await rpc.getPrivateKey();
      await storeData('web3authProvider', web3authProvider);
      // await storeData('web3auth', web3auth_l);
      await storeData('address', address);
      await storeData('pKey', pKey);
      await storeData(APP_STORAGE_USER_KEY, userinfo);
    }
    else {
      setWeb3auth(web3auth_l);
      setProvider(web3authProvider);
    }

    let userinfo = await getData(APP_STORAGE_USER_KEY);
    let pKey = await getData('pKey');
    let address = await getData('address');
    console.log("user address:", address);
    const userWallet = await new ethers.Wallet(pKey);
    await storeData(APP_STORAGE_USER_KEY, userinfo);
    // setNetworkId(getNetwork());
    // setProviderUri(getNetworkURI());
    // setNetworkName(getNetworkName());
    // setBlockExplorerUrl(getNetworkRPC());//todo set correct value    
    // setStaticProvider(getStaticProvider());
    setAddress(address);
    setNetworkId(getNetwork());
    setProviderUri(getNetworkURI());
    setNetworkName(getNetworkName());
    setBlockExplorerUrl(getNetworkRPC());//todo set correct value
    setWallet(userWallet);
    setUserInfo(userinfo);
    // setUserBalance(await rpc.getBalance());
    setStaticProvider(getStaticProvider());
    setConnected(true);

  }, [web3auth, provider]);

  const disconnect = useCallback(async () => {
    // setTimeout(() => window.location.reload(), 1000);
    if (web3auth) {
      await web3auth.logout();
    }
    setProvider(undefined);
    setUserInfo(null);
    await clearAllData();
    setTimeout(() => window.location.reload(), 500);
  }, []);
  const selectOrderDetails = useCallback(async (order: any, isShow: boolean) => {
    if (order != null && order.isProcessing) return false;
    setOrder(order);
    setIsOrderShow(isShow);
    setIsShowHours(false);
    setIsServeCliked(false);
  }, []);
  const onServe = useCallback(async (isServe: boolean) => {
    setIsServeCliked(isServe);
  }, []);
  const onClock = useCallback(async (isHoursClick: boolean) => {
    setIsShowHours(isHoursClick);
  }, []);
  //  const hideOrderDetails = useCallback( async (orders:any,isShow:boolean) => {
  //   setOrder("");
  //   setIsOrderShow(isShow);
  //  },[]);
  const web3AuthDetails = useMemo(
    () => ({
      connect,
      disconnect,
      address,
      connected,
      networkId,
      networkName,
      blockExplorerUrl,
      providerUri,
      provider,
      userInfo,
      userBalance,
      web3auth,
      staticProvider,
      order,
      isOrderShow,
      selectOrderDetails,
      // updateServed,
      // orderDetails,
      // hideOrderDetails,
      wallet,
      isShowHours,
      isServeCliked,
      onServe,
      onClock,
    }),
    [
      connect,
      disconnect,
      address,
      connected,
      networkId,
      networkName,
      blockExplorerUrl,
      providerUri,
      provider,
      userInfo,
      userBalance,
      web3auth,
      staticProvider,
      order,
      isOrderShow,
      selectOrderDetails,
      // updateServed,
      // orderDetails,
      // hideOrderDetails,
      wallet,
      isShowHours,
      isServeCliked,
      onServe,
      onClock,
    ]);
  return <Web3Context.Provider value={{ web3AuthDetails }}>{children}</Web3Context.Provider>;
};
