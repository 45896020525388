import "./Login.scss";
import React from "react";
import { useWeb3Context } from "../../hooks/web3Context";
import CapDefiLogo from "./../../assets/images/CapDeFi.png"
import Loot8HomeLogo from "./../../assets/images/Home-Logo.png"
import HeaderLogoBeta from "./../../assets/images/header-logo-beta.png"



function Login() {
  const { connect } = useWeb3Context();

  const onLogin = async () => {
    //setLoginInProgress(true);
    await connect();
    // setLoginInProgress(false);
  };

  return (
    <div className="login">
      <div className="login-bg">
        <div className="login-box">
          <div className="loot8" >
            <img src={HeaderLogoBeta} alt="Loot8"></img>
          </div>
          <div className="bartender-text">THE</div>
          <div className="bartender-text" style={{ paddingTop: 0}}>MERCHANT</div>
          <div className="portal-text">PORTAL</div>
          <button onClick={() => onLogin()} className="login_button">
            LOGIN
          </button>
        </div>
      </div>


    </div>
  );
}

export default Login;