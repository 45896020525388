

export const storeData = async(key: string, value: any) => {
    try {
        const jsonValue = JSON.stringify(value);
        await localStorage.setItem(key, jsonValue);
    } catch (error) {
        console.log("Strore data throws error:{}",key,error);
    }

}

export const getData = async(key: string) => {
    try {        
       const value = await localStorage.getItem(key);
       if(value !== null){
          return  JSON.parse(value);
       }
    } catch (error) {
        console.log("getData throws  error:{}",key,error);
    }

}


export const removeData = async(key: string) => {
    try {
        //const jsonValue = JSON.stringify(value);
       const value = await localStorage.removeItem(key);
       return value;
    } catch (error) {
        console.log("removeData throws  error:{}",key,error);
    }

}
export const getAllKeys = async () => {
    try {
      const value = await localStorage.getAllKeys();
      return value;
    } catch (e) {
      console.log("getAllKeys throws error",  e);
    }
  }

export const clearAllData = async () => {
    try {
      const value = await localStorage.clear();
      return value;
    } catch (e) {
      console.log("clearAllData throws error",  e);
    }
  }