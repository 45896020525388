
export enum UICustomOfferType {
    EMPTY = "",
    REGULAR = "Regular",
    FEATURED= 'Featured',
    //EXPERIENCE = 'Experience',
    EVENT = 'Event',
    //REGULAR_OR_FEATURED="REGULAR_OR_FEATURED",
    //DIGITAL_COLLECTIBLES="DIGITAL_COLLECTIBLES"
} 

export enum OfferType {
    NOTANOFFER,
    FEATURED,
    REGULAR
}