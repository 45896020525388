import "./Logout.scss";
import React from "react";
import { useWeb3Context } from "../../hooks/web3Context";

function Logout() {
  const {disconnect } = useWeb3Context();
  
  return (
    <div className="grid">
      <button className="logout_button" onClick={()=>disconnect()}>LOGOUT</button>
      </div>
  );
}

export default Logout;