import "./MainApp.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import Sidebar from "../Sidebar/Sidebar";
import OrderDetail from "../Serve/OrderDetail";
import Logout from "../Logout/Logout";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOrderFromEvent } from "../../slices/serveOrderSlice";
import { AppDispatch } from "../../store/store";
import { useAppSelector } from "../../hooks/hooks";
import Headers from "../Header/Header";
import WalletAddressAlert from "./WalletMsgDialog";
import { loadBartender, setLoading } from "../../slices/EntitySlice";
import CircularProgress from '@mui/material/CircularProgress';



function MainApp(props: object, props1: any) {
    const { connect, isOrderShow } = useWeb3Context();
    const { disconnect, connected, networkId, address, staticProvider, userInfo, wallet } = useWeb3Context();
    const dispatch = useDispatch<AppDispatch>();

    const isBartenderAuthorized = useAppSelector(state => state.entity.isBartenderAuthorized);
    const isBartenderLoading = useAppSelector(state => state.entity.loading);
    const orderLoading = useAppSelector(state => state.serverOrder.loading);
    const allOrders = useAppSelector(state => state.serverOrder.AllOfferDetails);
    const isProcessing = useAppSelector(state => state.serverOrder.processing);
    const searchText = useAppSelector(state => state.serverOrder.searchText);

    useEffect(() => {
        if (address != "" && !isBartenderAuthorized) {
            console.log("loadbartender", address, isBartenderAuthorized);
            const loadbartender = (async () => {
                await dispatch(setLoading());
                await dispatch(loadBartender({
                    networkID: networkId, provider: staticProvider, address, wallet: wallet
                }));
            });
            loadbartender();
        }
    }, [staticProvider, address, networkId, wallet, isBartenderAuthorized]);

    useEffect(() => {
        if (address != "" && isBartenderAuthorized) {
            console.log("homepage", address, isBartenderAuthorized);
            const loadEntity = (async () => {
                if (!isProcessing && searchText === '') {                   
                    await dispatch(getOrderFromEvent({
                        networkID: networkId, provider: staticProvider, address, wallet: wallet, fromBlock: 0, toBlock: null
                    }));
                    setTimeout(() => loadEntity(), 20000);
                }
                else {
                    setTimeout(() => loadEntity(), 3000);
                }
            });
            loadEntity();
        }
    }, [staticProvider, address, networkId, wallet, isBartenderAuthorized]);
    console.log(!isBartenderLoading, orderLoading, allOrders.length);
    return (
        <>
            <div className="fullPage">
                {!isBartenderLoading && orderLoading && allOrders.length == 0 &&
                    <div className="ld1">
                        <CircularProgress size={70} />
                    </div>
                }
                <Headers />

                {isBartenderAuthorized ? (
                    <div className="mainBody">

                        <div className="sidebar">
                            <Sidebar />
                        </div>
                        <div style={{ flex: 1, flexDirection: 'column'}}>
                            <div style={{ height: '90%'}}>
                                {isOrderShow ? (<OrderDetail />) : ""}
                            </div>
                            <div style={{ height: '10%' }}>
                                <Logout />
                            </div>
                        </div>

                    </div>
                ) : (!isBartenderLoading && <WalletAddressAlert />)}
            </div>


        </>
    );
}

export default MainApp;