import { configureStore } from '@reduxjs/toolkit'
import {orderSliceReducer} from "../slices/serveOrderSlice";
import {entitySliceReducer} from "../slices/EntitySlice";



const store = configureStore({
    reducer: {
        serverOrder: orderSliceReducer,
        entity: entitySliceReducer

    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;