import { ethers } from "ethers";
import { Entity__factory, Dispatcher__factory } from "./../typechain/factories";

export const GetCreateOrderMessage = (_reservationId: any, _data: any) => {
    const IOrder = new ethers.utils.Interface(Dispatcher__factory.abi);
    return IOrder.encodeFunctionData('dispatch', [_reservationId, _data]);
}

export const GetCreateCancelOrderMessage = (_reservationId: any) => {
    const ICancelOrder = new ethers.utils.Interface(Dispatcher__factory.abi);
    return ICancelOrder.encodeFunctionData('cancelReservation', [_reservationId]);
}

export const GetBanUserMessage = (patron: any, endin: any) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('addPatronToBlacklist', [patron, endin]);
}