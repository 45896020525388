
import "./Sidebar.scss";
import React from "react";
import { useWeb3Context } from "../../hooks/web3Context";
import CapDefiLogo from "./../../assets/images/CapDeF-logo.png"
import OrderCard from "./OrderCard";


import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { debounce } from "lodash";
import { searchOrders } from "../../slices/serveOrderSlice";


function Sidebar() {
  const { userInfo } = useWeb3Context();
  // const [allorderDetails1 ,setallorderDetails1] = useState(allorderDetails);
  const allOrders = useAppSelector(state => state.serverOrder.AllOfferDetails);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const loadOlderOrder = async () => {
    // await dispacth(onBanUser({ networkID: networkId, provider: staticProvider, address, orderInfo: order, wallet, banTime: hour }));    
  }
  const onOrdersSearch = (event) => {
    let text = event.target.value;
    setSearchText(text);
    dispatch(debounce(searchOrders({ searchText: text}), 500)); 
  };

  return (
    <>
    {
      allOrders && allOrders.length>0?( <div className="orderInfo">**List of Orders**</div>)
      :( <div className="orderInfo">**No Orders Found**</div>)
    }
      <div className="orderSearchBox">
        <input type="search" placeholder="Search" onChange={onOrdersSearch} value={searchText} className="orderSearchInput" />
      </div>
      <div className="orderDiv scroll">
        <div className="pp">
        {allOrders && allOrders.filter(z => !z.isServed).map((order: any, indx: number) => {
          return (
            <OrderCard orders={order} key={indx} indx={indx} />
          );
        })}
        {/* <div className="loadMore" onClick={loadOlderOrder}>click to load older order</div> */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;