import React from "react";
import { useEffect, useState } from "react";

import "./OrderCard.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import oldDrink from "./../../assets/images/OldFashion.png"
import tokenImg from "./../../assets/images/loot8-token.png"
import { truncate } from "fs";



function OrderCard({ orders, indx }: { orders: any, indx: any }) {
  const { selectOrderDetails } = useWeb3Context();
  function truncate(source, size) {
    if (source)
      return source.length > size ? source.slice(0, size - 1)+"..." : source;
    return "";
  }
  const userNameId = truncate(orders.userName, 11) + " #" + orders.userID;

  return (
    // <div className={"orderdetail " + (orders.isProcessing ? 'processing' : '')}>
    //   <div className="userProfile">
    //     <div className="userbox" onClick={() => selectOrderDetails(orders, true)} >
    //       <img src={orders.userImage} referrerPolicy="no-referrer" style={{ backgroundColor: orders.backgroundColor }} alt="profile"></img>
    //       <div className="userboxText" onClick={() => selectOrderDetails(orders, true)} >{userNameId}</div>
    //     </div>
    //   </div>

    //   <div className="drinkImgBox" onClick={() => selectOrderDetails(orders, true)}>
    //     <img src={orders.drinkImage} style={{ backgroundColor: orders.backgroundColor }} alt="drinks" onClick={() => selectOrderDetails(orders, true)}></img>
    //     <div className="priceBox">
    //       <div className="priceText">
    //         <div className="price">${orders.price.toFixed(2)} </div>
    //         <div className="token">
    //           <div className="num">{orders.token}</div>
    //           <div className="img"><img src={tokenImg} alt="token"></img></div>
    //         </div>
    //       </div>
    //     </div>

    //   </div>
    //   <div className="drinkTextBox" onClick={() => selectOrderDetails(orders, true)}>
    //     <div className="drinkText">{drinkName}</div>
    //   </div>
    // </div>
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 20, cursor: orders.isProcessing ? 'no-drop' : 'pointer', opacity: orders.isProcessing ? 0.6 : 1}} onClick={() => selectOrderDetails(orders, true)}>
      <div style={{backgroundColor: '#5E5EAA', height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="">
          <img className="userImg" src={orders.userImage} referrerPolicy="no-referrer" style={{ backgroundColor: orders.backgroundColor }} alt="profile"></img>
          <span className="userNameText">{userNameId}</span>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{ height: 150, alignItems: 'center', width: '70%' }}>
          <img src={orders.drinkImage} style={{ height: 140, objectFit: 'fill', width: '100%'}} alt="drinks"></img>
        </div>
        <div className="priceTokenContainer" style={{ width: '30%' }}>
          <div className="priceTxt">${orders.price.toFixed(2)} </div>
          <div style={{ borderColor: '#ffffff', borderWidth: 1, borderStyle: 'solid', width: '80%', marginLeft: 10}}></div>
          <div className="TokenContainer">
            <div className="tokenText">{orders.token}</div>
            <div className="tokenImg"><img src={tokenImg} alt="token"></img></div>
          </div>
        </div>
      </div>
      <div className="offerNameContainer">
          <div className="offerText">{orders.drinkName}</div>
          <div className="orderIDText">{"Ord - #" + orders.reservationId}</div>
      </div>
    </div>
  );
}
export default OrderCard;