import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Web3ContextProvider } from './hooks/web3Context';
import store from "../src/store/store";
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    
  // </React.StrictMode>
  <Web3ContextProvider>
      <Provider store ={store}>
       <App />
    </Provider> 
    </Web3ContextProvider>
);
