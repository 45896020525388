import { IPFS_URL } from "../constants";

export const getIPFSLink = (path: string): string => {
  return IPFS_URL + 'ipfs/' + path.replace(/ipfs:\/\//g, "");
 }
export const CheckAndGetIPFSLink = async (path: string): Promise<string> => {
  if (path.indexOf("ipfs:") != -1) {
    try{
    let imageResponse = await getIPFSData(path);
    let imageData = await imageResponse.text();
    //console.log(imageData);
    return imageData;
    }catch(e){
      console.log(e);
    }
    // return IPFS_URL + 'ipfs/' + path.replace(/ipfs:\/\//g, "");
  }
  else {
    return path;
  }
}
const wait = (timeout) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

const fetchPlus = (url, options = {}, retries) =>
  fetch(url, options)
    .then(async res => {
      if (res.ok) {
        return res
      }
      if (retries > 0) {
        console.log("waiting..");
        await wait(5000);
        console.log("restries", url, retries);
        return fetchPlus(url, options, retries - 1)
      }
      throw new Error(res.status + ":" + JSON.stringify(res))
    })
    .catch(async error => console.error(error.message))

export const getIPFSData = async (path: string): Promise<Response> => {
  console.log("url", getIPFSLink(path));
  return await fetchPlus(getIPFSLink(path), {
    method: "GET"
  }, 3);
}


// export const publishMessageForOrderDeliverIPFS = async (orderinfo) => {
//   const formData = new FormData();
//   formData.append("data", JSON.stringify({ id: 'asda' }));
//   const encodedTopic = 'u' + window.btoa('loot8-order-all').replaceAll('=', '');
//   console.log("order publisch", encodedTopic);
//   return fetch('https://loot8.dev:5001/api/v0/pubsub/pub?arg=ubG9vdDgtb3JkZXItYWxs', {
//     method: 'POST',
//     body: formData,

//     headers: { "Host": "loot8.dev:5001" },
//   }).then((response) => {
//     if (response.status == 200)
//       return response
//     return null
//   }).catch((err) => {
//     console.error(err)
//   });

// }

// export const subscribeToOrderPurchase = (entityAddress, callbackFn: any) => {

//   var http = new XMLHttpRequest();
//   const encodedTopic = 'u' + window.btoa('loot8-order-' + entityAddress).replaceAll('=', '');
//   var url = 'https://loot8.dev:5001/api/v0/pubsub/sub?arg=' + encodedTopic;
//   http.open('POST', url, true);

//   //Send the proper header information along with the request
//   http.setRequestHeader("Host", "loot8.dev:5001");

//   http.onreadystatechange = function () {
//     if (http.status == 200 && http.responseText && http.responseText.length > 0) {
//       console.log("order created");
//       const allMessages = http.responseText.split('\n');
//       console.log(allMessages);
//       let message: string = JSON.parse(allMessages[allMessages.length - 2]).data;
//       const encodedData = window.btoa(message.substring(1, message.length));
//       if (encodedData) {
//         const jsonData = JSON.parse(encodedData.replaceAll("\x00", ""));
//         //   if (ethers.utils.verifyMessage(jsonData.message, jsonData.signature) == friend.wallet) {
//         //     callbackFn(friend, jsonData.message);
//         //   }
//       }
//     }
//   }
//   http.send();
// }