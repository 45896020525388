export function setAll(state: any, properties: any) {
    if (properties) {
      const props = Object.keys(properties);
      props.forEach(key => {
        console.log(key,properties[key]);
        state[key] = properties[key];
      });
    }
  }

  
  export {}